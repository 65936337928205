<template>
    <div class="LevalDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="企业名称">
                            <el-input v-model="form.companyName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构组">
                            <el-input v-model="form.name" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="form.creator" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建时间">
                            <el-input v-model="form.createTime" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" size="small" @click="handleAdd">添加</el-button>
            <el-button type="primary" size="small" @click="save">保存</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table border style="width: 100%" :data="tableData">
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="等级数值" prop="level" width="120" />
                <el-table-column label="等级名称" width="120" prop="name">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.name" size="mini" />
                    </template>
                </el-table-column>
                <el-table-column label="积分" width="120" prop="maxPoint">
                    <template slot-scope="scope">
                        <ef-price-input
                            v-model.number="scope.row.maxPoint"
                            :disabled="scope.row.level == 1"
                            :precision="0"
                        />
                    </template>
                </el-table-column>
                <el-table-column label="折扣" width="200" prop="discount">
                    <template slot-scope="scope">
                        <ef-price-input v-model="scope.row.discount" :precision="1" :min="0.1" :max="10" />
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80" header-align="center">
                    <template slot-scope="scope" v-if="scope.$index == tableData.length - 1">
                        <el-button
                            size="mini"
                            type="danger"
                            @click="deleteRow(scope.$index, tableData, scope.row)"
                            v-if="scope.row.level != 1"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>
<script>
import Util from '../../../../js/Util';
import UrlUtils from '../../../../js/UrlUtils';
import EfPriceInput from '../../../components/EfPriceInput';

export default {
    name: 'LevelEdit',
    components: { EfPriceInput },
    props: ['form'],
    data() {
        return {
            queryData: {
                page: 1,
                search: '',
                deptCode: this.form.deptCode,
                status: 1,
                limit: Util.Limit,
            },
            meta: {
                suppliers: [],
            },
            level: {
                delCodes: [],
                supplierCode: '',
                deptCode: '',
                remark: '',
            },
            url: {
                page: '/member/level/queryDetail',
                save: '/member/level/editExtend',
            },
            page: {
                total: 0,
            },
            codes: [],
            tableData: [],
        };
    },
    mounted() {
        this.handleQuery();
    },
    methods: {
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },
        handleAdd() {
            this.tableData = this.tableData.concat({
                level: this.tableData.length + 1,
                code: '',
            });
        },
        save() {
            const _this = this;
            const _a = [];
            const _array = [];
            for (let i = 0; i < this.tableData.length; i++) {
                const value = this.tableData[i];
                _array.push(value.code);
                if (Util.isEmpty(value.name) || Util.isEmpty(value.maxPoint) || Util.isEmpty(value.discount)) {
                    _this.$message.error('请输入所有的等级名称、积分和折扣');
                    return;
                }
                if (i >= 1 && value.maxPoint <= this.tableData[i - 1].maxPoint) {
                    _this.$message.error('等级积分应大于上一等级积分');
                    return false;
                }
            }
            this.codes.forEach((value) => {
                if (!_array.includes(value)) {
                    _a.push(value);
                }
            });
            const _params = {
                deptGroupCode: this.form.deptGroupCode,
                codes: this.tableData.map((d) => d.code),
                names: this.tableData.map((d) => d.name),
                maxPoints: this.tableData.map((d) => d.maxPoint),
                discounts: this.tableData.map((d) => d.discount),
                delCodes: _a,
            };
            UrlUtils.PatchRemote(this, this.url.save, _params, null, () => {
                this.$message.success('保存成功');
                _this.goBackAndReload();
            });
        },
        deleteRow(index, rows) {
            rows.splice(index, 1);
        },
        handleQuery() {
            const _this = this;
            const _params = {
                params: { code: this.form.code },
            };
            Util.queryTable(_this, _this.url.page, _params, (data) => {
                const l = data.data.memberLevelDos.length;
                const _a = [];
                data.data.memberLevelDos.forEach((v) => {
                    this.codes.push(v.code);
                    _a.push(v);
                });
                _a.forEach((v, i) => {
                    _a[i] = data.data.memberLevelDos[l - i - 1];
                });
                this.tableData = _a;
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
